import axios from 'axios';
import router from '../../router.js'
import env from "../../env.json";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";

const token = localStorage.getItem('access_token')
const userData = null
const initialState = token
    ? { status: { loggedIn: true }, token }
    : { status: { loggedIn: false }, token: null };
export const auth = {
    namespaced: true,
    state: { initialState, userData },
    actions: {
        login({ commit }, credentials) {
            axios.post(`${env.host}/auth`, credentials)
                .then(res => {
                    console.log(res);
                    const token = res.data.access_token;
                    const decodeData = jwt_decode(token)
                    console.log(decodeData);
                    commit('decodeData', decodeData)
                    localStorage.setItem("role", decodeData.role)
                    localStorage.setItem("access_token", token)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    commit('loginSuccess', { token })
                    router.push("/adminpage");
                    return Promise.resolve(token);
                })
                .catch(err => {
                    console.log(err);
                    commit('loginFailure')
                    return Promise.reject(err);
                })
        },
        logout({ commit }) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('role');
            commit('logout')
        },
    },
    mutations: {
        decodeData(state, userData) {
            state.userData = userData
        },
        loginSuccess(state, token) {
            state.token = token
            state.initialState.status.loggedIn = true;
        },
        loginFailure(state) {
            state.initialState.status.loggedIn = false;
            state.token = null;
        },
        logout(state) {
            state.initialState.status.loggedIn = false;
            state.token = null;
        },
    }
}