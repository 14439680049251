import Vue from "vue";
import router from "vue-router";
import store from "./store";
import { staticRoutes, dinamycRoutes } from "./routes";
Vue.use(router);

const VueRouter = new router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...staticRoutes, ...dinamycRoutes],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});
VueRouter.beforeEach((to, from, next) => {
  if (store.state.auth.initialState.status.loggedIn && to.name === 'adminlogin') {
    next({ name: "adminpage" })
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      to.name !== "adminlogin" &&
      !store.state.auth.initialState.status.loggedIn
    ) {
      next({
        path: "/admin",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default VueRouter;
