import axios from 'axios'
import i18n from '../i18n.js'
import Swal from "sweetalert2";
import router from '../router.js'

axios.interceptors.response.use(undefined,
    (error) => {
        console.log(error);
        switch (error.response.status) {
            case 400:
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: i18n.t("errors.enter_parameters"),
                });
                break;
            case 401:
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: i18n.t("errors.incorrect"),
                }).then(()=>{
                    router.push("/admin")
                    localStorage.removeItem("access_token")
                    localStorage.removeItem("role")
                })
                break;
            case 404:
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: i18n.t("errors.not_found"),
                });
                break;
            case 413:
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: i18n.t("errors.too_long"),
                });
                break;
            case 500:
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: i18n.t("errors.server_error"),
                });
        }
        return Promise.reject(error.response);
    }
);