
import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './modules/auth'
import '../main.js'

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    modules: {
        auth,
    },

})