import blogSlugs from "../slugs/blogSlugs.json";
import careerSlugs from "../slugs/careerSlugs.json";
import csrSlugs from "../slugs/csrSlugs.json";
import newsSlugs from "../slugs/newsSlugs.json";
import teamSlugs from "../slugs/teamSlugs.json";

const staticRoutes = [
  {
    path: "/",
    name: "home",
    component: () => import("./views/UserPages/Main/Home.vue"),
  },

  {
    path: "/legalstatement",
    name: "legalstatement",
    component: () => import("./components/Privacy/LegalStatement.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("./components/Privacy/PrivacyPolicy.vue"),
  },
  {
    path: "/newTeamPage",
    name: "newTeamPage",
    component: () => import("./views/UserPages/Team/NewTeamPage.vue"),
  },
  {
    path: "/csr",
    name: "csr",
    component: () => import("./views/UserPages/Csr/CsrPage.vue"),
  },
  {
    path: "/newNews",
    name: "newNews",
    component: () => import("./views/UserPages/News/NewNews/NewNewsMain.vue"),
  },
  {
    path: "/blogPage",
    name: "blogPage",
    component: () => import("./views/UserPages/Blog/NewBlog/BlogMain.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("./views/UserPages/Contact/ContactPage.vue"),
  },
  {
    path: "/values",
    name: "values",
    component: () => import("./views/UserPages/Values/ValuesPage.vue"),
  },
  {
    path: "/company",
    name: "company",
    component: () => import("./views/UserPages/Company/CompanyMission.vue"),
  },
  {
    path: "/history",
    name: "HistoryMain",
    component: () => import("./views/UserPages/History/historyNew.vue"),
  },
  {
    path: "/exclusiveMedicaments",
    name: "exclusiveMedicaments",
    component: () =>
      import("./views/UserPages/Brands/NewExclusive/ExcluisveBrandsMain.vue"),
  },
  {
    path: "/vacancy",
    name: "vacancy",
    component: () =>
      import("./views/UserPages/Vacancy/VacancyElements/VacancyMain.vue"),
  },
  {
    path: "/distributions",
    name: "distributions",
    component: () =>
      import("./views/UserPages/Distributions/Distributions.vue"),
  },
  {
    path: "/partners",
    name: "partners",
    component: () => import("./views/UserPages/Partners/CommonPartners.vue"),
  },
  {
    path: "/csr/:slug",
    name: "csrDetail",
    component: () =>
      import("./views/UserPages/Csr/CsrDetails/CsrDetailsPage.vue"),
    meta: {
      sitemap: {
        slugs: csrSlugs,
      },
    },
  },

  {
    path: "/news/:slug",
    name: "newsDetailPage",
    component: () =>
      import("./views/UserPages/News/new-news/NewsDetailPage.vue"),
    meta: {
      sitemap: {
        slugs: newsSlugs,
      },
    },
  },
  {
    path: "/blog/:slug",
    name: "BlogDetailPage",
    component: () =>
      import("./views/UserPages/Blog/new-blog/BlogDetailPage.vue"),
    meta: {
      sitemap: {
        slugs: blogSlugs,
      },
    },
  },
  {
    path: "/team/:slug",
    name: "teamDetail",
    component: () => import("./components/Team/NewTeamDetail.vue"),
    meta: {
      sitemap: {
        slugs: teamSlugs,
      },
    },
  },
  {
    path: "/career/:slug",
    name: "readingVacancy",
    component: () =>
      import("./views/UserPages/Vacancy/CurrentVacancy/ReadingVacancy.vue"),
    meta: {
      sitemap: {
        slugs: careerSlugs,
      },
    },
  },
];

const dinamycRoutes = [
  // {
  //   path: "/NewsDetailPage/NewsDetailPage/:slug",
  //   name: "NewsDetailPage",
  //   component: () =>
  //     import("./views/UserPages/News/new-news/NewsDetailPage.vue"),
  // },

  {
    path: "/empty",
    name: "empty",
    component: () =>
      "./views/UserPages/Vacancy/VacancyElements/EmptyVacancy.vue",
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("./views/UserPages/NotFound/NotFound.vue"),
  },

  {
    path: "/searchPage/:input",
    name: "searchPage",
    component: () => import("./views/UserPages/SearchComponnet/SearchPage.vue"),
  },

  {
    path: "/:category/:slug/medicamentsDetail",
    name: "medicamentsDetail",
    component: () =>
      import("./views/UserPages/Brands/MedicamentsDetailPage/Detail.vue"),
  },

  {
    path: "/vacancyForm",
    name: "vacancyForm",
    component: () => import("./views/UserPages/Vacancy/VacancyForm.vue"),
  },

  {
    path: "/admin",
    name: "adminlogin",
    component: () => import("./views/AdminPage/AdminLogin.vue"),
  },
  {
    path: "/admin/:id",
    name: "forgotPass",
    component: () => import("./components/Admin/ForgotPass/ForgotPass.vue"),
  },
  {
    path: "/admin/forgot-password/:id",
    name: "PassChange",
    component: () => import("./components/Admin/ForgotPass/PassChange.vue"),
  },
  {
    path: "/adminpage",
    name: "adminpage",
    component: () => import("./views/AdminPage/AdminPage.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "addcsr",
        name: "addcsr",
        component: () => import("./components/Admin/GetComponents/Csr.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addcareer",
        name: "addcareer",
        component: () => import("./components/Admin/GetComponents/Career.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addcareerInfo",
        name: "addcareerInfo",
        component: () =>
          import("./components/Admin/GetComponents/CareerInfo.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addCareerCategory",
        name: "addCareerCategory",
        component: () =>
          import("./components/Admin/GetComponents/CareerCategory.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addcompany",
        name: "addcompany",
        component: () => import("./components/Admin/GetComponents/Company.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addvalues",
        name: "addvalues",
        component: () => import("./components/Admin/GetComponents/Values.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addhistory",
        name: "addhistory",
        component: () => import("./components/Admin/GetComponents/History.vue"),
      },
      {
        path: "addvideo",
        name: "addvideo",
        component: () => import("./components/Admin/GetComponents/Video.vue"),
      },
      {
        path: "addHero",
        name: "addHero",
        component: () => import("./components/Admin/GetComponents/Hero.vue"),
      },
      {
        path: "addCards",
        name: "addCards",
        component: () => import("./components/Admin/GetComponents/Cards.vue"),
      },
      {
        path: "addteam",
        name: "addteam",
        component: () => import("./components/Admin/GetComponents/Team.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addteamInfo",
        name: "addteamInfo",
        component: () =>
          import("./components/Admin/GetComponents/TeamInfo.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addPartners",
        name: "addPartners",
        component: () =>
          import("./components/Admin/GetComponents/ForeignPartners.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addPartnersInfo",
        name: "addPartnersInfo",
        component: () =>
          import("./components/Admin/GetComponents/ForeignPartnersInfo.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addExclusiveBrands",
        name: "addExclusiveBrands",
        component: () =>
          import("./components/Admin/GetComponents/ExclusiveBrands.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "adddistribution",
        name: "adddistribution",
        component: () =>
          import("./components/Admin/GetComponents/Distribution.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addNews",
        name: "addNews",
        component: () => import("./components/Admin/GetComponents/News.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addBlogs",
        name: "addBlogs",
        component: () => import("./components/Admin/GetComponents/Blogs.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addBlogInfo",
        name: "addBlogInfo",
        component: () =>
          import("./components/Admin/GetComponents/BlogInfo.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addCategory",
        name: "addCategory",
        component: () =>
          import("./components/Admin/GetComponents/Category.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addPrivacy",
        name: "addPrivacy",
        component: () => import("./components/Admin/GetComponents/Privacy.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addLegal",
        name: "addLegal",
        component: () =>
          import("./components/Admin/GetComponents/LegalStatement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addContact",
        name: "addContact",
        component: () => import("./components/Admin/GetComponents/Contact.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addContactInfo",
        name: "addContactInfo",
        component: () => import("./components/Admin/GetComponents/ContactInfo.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addEmail",
        name: "addEmail",
        component: () => import("./components/Admin/GetComponents/ConfidentialEmail.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addUser",
        name: "addUser",
        component: () => import("./components/Admin/GetComponents/Users.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "addStickyButton",
        name: "addStickyButton",
        component: () => import("./components/Admin/GetComponents/StickyButton.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];
export { staticRoutes, dinamycRoutes };
