import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import VueRouter from "vue-router";
import { i18n } from "./i18n";
import axios from "axios";
import VueMeta from "vue-meta";
import Lingallery from "lingallery";
import store from "./store/index.js";
import "./errors/index.js";

import VueGoodTablePlugin from 'vue-good-table';

// import the styles 
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { Multiselect } from "vue-multiselect";
Vue.use(Multiselect);
import "vue-multiselect/dist/vue-multiselect.min.css";

import { required, alpha, max, min, between } from "vee-validate/dist/rules";
import {
  extend,
  localize,
  ValidationProvider,
  ValidationObserver,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import ka from "vee-validate/dist/locale/ka.json";
import VueSweetalert2 from "vue-sweetalert2";

import VueSocialSharing from "vue-social-sharing";
import vueHeadful from "vue-headful";

Vue.component("vue-headful", vueHeadful);

const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};

Vue.use(VueSweetalert2, options);
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(VueSocialSharing);

Vue.component("lin-gallery", Lingallery);

// import imageZoom from "vue-image-zoomer";

Vue.use(VueRouter);

const token = localStorage.getItem("access_token");
if (token) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

Vue.use(BootstrapVue);

localize({
  en,
  ka,
});

extend("required", required);
extend("alpha", alpha);
extend("max", max);
extend("min", min);
extend("between", between);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  store,
  router,
  i18n,
}).$mount("#app");
