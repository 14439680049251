import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./locales/en.json";
import ka from "./locales/ka.json";

Vue.use(VueI18n);
var lang = "ka";
if (localStorage.getItem("lang")) {
  lang = localStorage.getItem("lang");
}
console.log(lang);
export const i18n = new VueI18n({
  locale: lang,
  messages: { ka, en },
});

export default i18n;
