<template>
  <div class="sticky" v-if="allStickyButton.url">
    <a :href="allStickyButton.url" target="_blank" class="linkButton">
      <img :src="`${server_url}/${allStickyButton.image}`" alt="" />
    </a>
  </div>
</template>
<script>
import axios from "axios";
import env from "../../env.json";
export default {
  data() {
    return {
      allStickyButton: [],
      server_url: env.server_url,
      isLoading: false,
    };
  },
  created() {
    this.getStickyButton();
  },
  methods: {
    getStickyButton() {
      this.isLoading = true;
      axios.get(`${env.host}/get/informations/sticky`).then((res) => {
        this.isLoading = false;
        console.log(res);
        this.allStickyButton = res.data.item;
      });
    },
  },
};
</script>

<style scoped>
.sticky {
  background-color: #7e5493a4;
  position: fixed;
  overflow: auto;
  border-radius: 50%;
  top: 73%;
  float: right;
  right: 10px;
  width: 50px;
  height: 50px;
  z-index: 9999;
  display: flex;
  align-items: center;
  margin: auto;
  transition: 0.1s all ease-in-out;
}
.sticky:hover {
  background-color: #7e5493;
  cursor: pointer;
  transition: 0.3s;
}
.sticky img {
  display: flex;
  align-items: center;
  margin: auto;
  width: 30px;
  transition: 0.2s all ease-in-out;
}
.linkButton {
  display: flex;
  align-items: center;
  margin: auto;
}
.sticky:hover img {
  transform: scale(1.2);
  width: 32px;
}
.sticky:hover {
  transform: scale(1.2);
  border-radius: 10%;
}
@media all and (max-width: 1002px) {
  .sticky {
    top: 50%;
  }
}
</style>