<template>
  <div id="app">
    <StickyButton v-if="!sticky_button_path.includes($route.name)" />
    <router-view :key="$route.fullPath"> </router-view>
  </div>
</template>

<script>
import StickyButton from "./views/UserPages/StickyButton.vue";
export default {
  name: "App",
  components: { StickyButton },
  data() {
    return {
      sticky_button_path: [
        "adminlogin",
        "forgotPass",
        "PassChange",
        "adminpage",
        "addcsr",
        "addcareer",
        "addcareerInfo",
        "addCareerCategory",
        "addvalues",
        "addhistory",
        "addcompany",
        "addvideo",
        "addHero",
        "addCards",
        "addteam",
        "addteamInfo",
        "addPartners",
        "addPartnersInfo",
        "addExclusiveBrands",
        "adddistribution",
        "addNews",
        "addBlogs",
        "addBlogInfo",
        "addCategory",
        "addLegal",
        "addPrivacy",
        "addContactInfo",
        "addContact",
        "addUser",
        "addEmail",
        "addStickyButton",
      ],
    };
  },
  mounted() {},
};
</script>

<style>
.purple {
  color: #7e5493;
}
.blackColor {
  color: #4d4d4c;
}
.mtavruli {
  font-family: mtavruli;
}

.dejavuBOLD {
  font-family: dejavuBOLD;
}
.mtavruliBOLD {
  font-family: mtavruliBOLD;
}

@font-face {
  font-family: dejavu;
  src: url("./assets/fonts/DejaVuSansCondensed.ttf");
}

@font-face {
  font-family: dejavuBOLD;
  src: url("./assets/fonts/DejaVuSansCondensed-Bold.ttf");
}

@font-face {
  font-family: mtavruliBOLD;
  src: url("./assets/fonts/BPG_DejaVuSansMt_Bold.ttf");
}
@font-face {
  font-family: mtavruli;
  src: url("./assets/fonts/BPG_DejaVuSansMt.ttf");
}

@font-face {
  font-family: inside_text;
  src: url("./assets/fonts/bpg-arial-webfont.ttf");
}

* {
  font-family: dejavu;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
.last_added_item,
li {
  -webkit-tap-highlight-color: transparent;
}
</style>
